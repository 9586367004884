<template>
  <div class="myMaintenanceReport">
    <div class="myMaintenanceReport-content">
      <div class="nav" ref="nav">
        <div
          :class="['item', { active: currentIndex == index }]"
          :id="`item_${index}`"
          v-for="(item, index) in navList"
          :key="index"
          @click="handelChangeNav(item, index)"
        >
          <span> {{ item.label }} </span>
          <img v-if="currentIndex == index" src="./img/tab-icon.png" alt="" />
        </div>
      </div>
      <div class="content">
        <div class="item" v-if="currentIndex == 1">
          <div class="item-l">设备名称</div>
          <div class="item-r">
            <v-input
              readonly
              v-model="deviceName"
              inputAlign="right"
              placeholder="请输入设备名称"
            ></v-input>
          </div>
          <div class="img" @click="handelScanQRCodes">
            <img src="./img/saoma.png" alt="" />
          </div>
        </div>
        <div class="explain">
          <div class="label">损坏说明</div>
          <div class="explain-text">
            <div class="text-area">
              <v-input
                v-model="formList.malfunctionDescription"
                :maxlength="200"
                showWordLimit
                placeholder="请输入，200字以内..."
                type="textarea"
              ></v-input>
            </div>
            <div class="upload"></div>
          </div>
        </div>
        <div class="address" v-if="currentIndex == 0">
          <span>{{ place ? place : "报修地点" }}</span>
          <span @click="handelSelect"> 请选择 </span>
        </div>
      </div>
    </div>
    <div class="footer">
      <div @click="handelClickSubmit">提交</div>
    </div>
  </div>
</template>

<script>
import * as dd from "dingtalk-jsapi";
import { scanQrcode } from "@/utils/utils.js";
// import updateFile from "./components/updateFile";
import { submitWorkInfo, getServiceNum } from "./api.js";
export default {
  name: "myMaintenanceReport",
  // components: {
  //   updateFile,
  // },
  data() {
    return {
      place: "", //地点
      formList: {
        equipmentNumber: "",
        malfunctionDescription: "", //故障描述
        spaceId: "", //故障地点
        applyPeople: this.$store.state.account, //报修人
        applyCompany: "其他", //单位：目前写死其他
        registerPeople: this.$store.state.account, //登记人
        applyPhone: this.$store.state.mobile, //手机号
        majorType: "", //专业
        optUser: this.$store.state.userId,
        workSource: 2, //2为扫码上报，4 移动端上报
      },
      deviceName: "", //设备名称
      currentIndex: 0,
      navList: [
        {
          label: "手动上报",
          value: 4,
        },
        {
          label: "扫码上报",
          value: 2,
        },
      ],
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    target() {
      return this.$store.state.target;
    },
  },
  created() {
    // this.getServiceNum();
    if (JSON.parse(sessionStorage.getItem("formList"))) {
      const res = JSON.parse(sessionStorage.getItem("formList"));
      this.formList = res;
    }
  },
  mounted() {
    window.scanCallback = this.scanCallback;
    const { place, code, result } = this.$route.query;
    if (place != undefined) {
      this.place = place;
      this.formList.spaceId = code;
      sessionStorage.removeItem("formList");
    }
    //扫描获取的内容
    if (result != undefined) {
      this.currentIndex = 1;
      const deviceSn = JSON.parse(decodeURIComponent(result));
      this.getEquipmentInfo(deviceSn);
    }
  },
  methods: {
    //获取设备信息
    getEquipmentInfo(deviceSn = "5522--00") {
      this.$axios
        .get(`/gateway/hc-device/iot/device/${deviceSn}/detailBySn`)
        .then((res) => {
          if (res.code == 200) {
            const { data } = res;
            this.deviceName = data.deviceName;
            this.formList.spaceId = data.spaceId;
            this.formList.equipmentNumber = deviceSn;
          }
        });
    },
    //切换nav
    handelChangeNav(item, index) {
      this.currentIndex = index;
      this.getServiceNum();
      this.initFormList();
    },
    //初始化数据
    initFormList() {
      this.deviceName = "";
      this.formList.malfunctionDescription = "";
      this.formList.spaceId = "";
      this.formList.equipmentNumber = "";
      this.place = "";
    },
    //扫码回调
    scanCallback(str) {
      this.currentIndex = 1;
      console.log("扫码内容" + str);
      this.getEquipmentInfo(str);
    },
    //点击扫码
    handelScanQRCodes() {
      let _this = this;
      if (this.target == "yshgj") {
        scanQrcode();
      } else {
        var userAgentStr = window.navigator.userAgent;
        if (/DingTalk/.test(userAgentStr)) {
          dd.ready(() => {
            dd.scan({
              type: "qr",
            })
              .then((res) => {
                let deviceSn = res.text;
                _this.getEquipmentInfo(deviceSn);
              })
              .catch();
          });
        } else {
          if (window.location.origin.indexOf("https") != -1) {
            this.$router.push({
              name: "scanQrCodes",
              query: {
                url: "maintenanceFeedback",
              },
            });
          }
        }
      }
    },
    //获取工单编号
    getServiceNum() {
      this.$axios.get(getServiceNum).then((res) => {
        if (res.code == 200) {
          this.formList.serviceNumber = res.data;
        }
      });
    },
    //校验
    valid() {
      let result = true;
      if (!this.formList.malfunctionDescription) {
        this.$toast("请输入损坏说明");
        return (result = false);
      }
      if (!this.formList.spaceId) {
        this.$toast("选择报修地点");
        return (result = false);
      }
      return result;
    },

    //提交工单
    handelClickSubmit() {
      const result = this.valid();
      if (!result) return;
      this.currentIndex == 0
        ? (this.formList.workSource = 4)
        : (this.formList.workSource = 2);
      let params = this.formList;
      if (params.workSource == 2) {
        params.workType = 3;
      }
      this.$axios.post(submitWorkInfo, params).then((res) => {
        if (res.code == 200) {
          this.$router.push({
            name: "maintenanceReport",
          });
          this.$toast({ message: res.msg, duration: 2000 });
        }
      });
    },
    //选择报修地点
    handelSelect() {
      sessionStorage.setItem("formList", JSON.stringify(this.formList));
      this.$router.push({
        name: "maintenanceReportEditPlace",
        query: {
          path: this.$route.name,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.myMaintenanceReport {
  width: 100%;
  height: 100vh;
  background: #f9f9f9;
  padding-top: 84px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .myMaintenanceReport-content {
    width: 100%;
    flex: 1;
    background: #f9f9f9;
    // padding: 44px 36px 54px 38px;
    box-sizing: border-box;
    .nav {
      background: #ffffff;
      margin-bottom: 20px;
      padding: 48px 112px 28px 104px;
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      box-sizing: border-box;
      justify-content: space-between;

      .item {
        box-sizing: border-box;
        width: auto;
        flex-shrink: 0;
        text-align: center;
        position: relative;
        &.active {
          span {
            font-size: 32px;
            font-weight: 600;
            color: #333333;
            line-height: 44px;
          }
        }
        &:not(:last-child) {
          margin-right: 68px;
        }
        span {
          font-size: 28px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 40px;
        }
        img {
          position: absolute;
          right: -22px;
          top: -12px;
          width: 28px;
          height: 26px;
        }
      }
    }
    .content {
      box-sizing: border-box;
      padding: 44px 34px 54px 38px;
      background-color: #fff;
      .item {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        .item-l {
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 40px;
        }
        .item-r {
          flex: 1;
        }
        .img {
          width: 32px;
          height: 32px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .explain {
        box-sizing: border-box;
        .label {
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 40px;
          margin-bottom: 14px;
        }
        .explain-text {
          padding: 28px 32px 28px 34px;
          box-sizing: border-box;
          width: 100%;
          background: rgba(0, 0, 0, 0.04);
          .text-area {
            box-sizing: border-box;
            margin-bottom: 34px;
            ::v-deep .van-cell {
              padding: 0;
              background-color: transparent;
            }
          }
        }
      }
      .tips {
        margin-top: 24px;
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        line-height: 46px;
      }
      .address {
        margin-top: 24px;
        span {
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 46px;
          &:first-child {
            margin-right: 10px;
          }
          &:last-child {
            cursor: pointer;
            display: inline-block;
            color: #ff9b55;
            text-align: center;
            line-height: 50px;
            width: 128px;
            height: 50px;
            background: rgba(254, 68, 22, 0.05);
            border-radius: 6px;
          }
        }
      }
    }
  }
  .footer {
    width: 100%;
    background: #ffffff;
    padding: 48px 36px 90px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    > div {
      text-align: center;
      width: 226px;
      height: 74px;
      line-height: 74px;
      background: #fe4416;
      border-radius: 74px;
      font-size: 30px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
    }
  }
}
</style>
